*,
:before,
:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
:before,
:after {
  --tw-content: "";
}
html,
:host {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  line-height: inherit;
}
hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
button,
select {
  text-transform: none;
}
button,
input:where([type="button"]),
input:where([type="reset"]),
input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
:-moz-focusring {
  outline: auto;
}
:-moz-ui-invalid {
  box-shadow: none;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
legend {
  padding: 0;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
dialog {
  padding: 0;
}
textarea {
  resize: vertical;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}
button,
[role="button"] {
  cursor: pointer;
}
:disabled {
  cursor: default;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}
img,
video {
  max-width: 100%;
  height: auto;
}
[hidden] {
  display: none;
}
*,
:before,
:after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.pointer-events-none {
  pointer-events: none;
}
.pointer-events-auto {
  pointer-events: auto;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.collapse {
  visibility: collapse;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: sticky;
}
.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.bottom-0 {
  bottom: 0;
}
.bottom-1 {
  bottom: 0.25rem;
}
.bottom-1\/2 {
  bottom: 50%;
}
.left-0 {
  left: 0;
}
.left-4 {
  left: 1rem;
}
.left-\[var\(--left\)\] {
  left: var(--left);
}
.right-0 {
  right: 0;
}
.right-4 {
  right: 1rem;
}
.top-0 {
  top: 0;
}
.top-1 {
  top: 0.25rem;
}
.top-1\/2 {
  top: 50%;
}
.top-\[var\(--top\)\] {
  top: var(--top);
}
.isolate {
  isolation: isolate;
}
.z-10 {
  z-index: 10;
}
.z-50 {
  z-index: 50;
}
.z-\[90\] {
  z-index: 90;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-7 {
  margin-bottom: 1.75rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-9 {
  margin-bottom: 2.25rem;
}
.mb-\[18px\] {
  margin-bottom: 18px;
}
.mb-\[2\.375rem\] {
  margin-bottom: 2.375rem;
}
.mb-\[51px\] {
  margin-bottom: 51px;
}
.ml-0 {
  margin-left: 0;
}
.mr-\[12px\] {
  margin-right: 12px;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-\[2\.125rem\] {
  margin-top: 2.125rem;
}
.mt-\[41px\] {
  margin-top: 41px;
}
.\!block {
  display: block !important;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.\!inline {
  display: inline !important;
}
.inline {
  display: inline;
}
.\!flex {
  display: flex !important;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.table {
  display: table;
}
.inline-table {
  display: inline-table;
}
.table-caption {
  display: table-caption;
}
.table-cell {
  display: table-cell;
}
.table-column {
  display: table-column;
}
.table-column-group {
  display: table-column-group;
}
.table-footer-group {
  display: table-footer-group;
}
.table-header-group {
  display: table-header-group;
}
.table-row-group {
  display: table-row-group;
}
.table-row {
  display: table-row;
}
.flow-root {
  display: flow-root;
}
.grid {
  display: grid;
}
.contents {
  display: contents;
}
.list-item {
  display: list-item;
}
.\!hidden {
  display: none !important;
}
.hidden {
  display: none;
}
.aspect-square {
  aspect-ratio: 1 / 1;
}
.h-1 {
  height: 0.25rem;
}
.h-2\/5 {
  height: 40%;
}
.h-3 {
  height: 0.75rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-auto {
  height: auto;
}
.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}
.h-full {
  height: 100%;
}
.min-h-screen {
  min-height: 100vh;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-\[12px\] {
  width: 12px;
}
.w-\[24px\] {
  width: 24px;
}
.w-full {
  width: 100%;
}
.max-w-52 {
  max-width: 13rem;
}
.max-w-96 {
  max-width: 24rem;
}
.max-w-\[100\%\] {
  max-width: 100%;
}
.max-w-\[26\.25rem\] {
  max-width: 26.25rem;
}
.max-w-\[26\.875rem\] {
  max-width: 26.875rem;
}
.max-w-\[28\.938rem\] {
  max-width: 28.938rem;
}
.max-w-\[33rem\] {
  max-width: 33rem;
}
.max-w-\[500px\] {
  max-width: 500px;
}
.max-w-\[53\.875rem\] {
  max-width: 53.875rem;
}
.max-w-\[768px\] {
  max-width: 768px;
}
.max-w-\[80\.875rem\] {
  max-width: 80.875rem;
}
.max-w-full {
  max-width: 100%;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-none {
  flex: none;
}
.flex-shrink,
.shrink {
  flex-shrink: 1;
}
.flex-grow,
.grow {
  flex-grow: 1;
}
.border-collapse {
  border-collapse: collapse;
}
.-translate-y-1 {
  --tw-translate-y: -0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-1 {
  --tw-translate-y: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-1\/2 {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-\[-180deg\] {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform-none {
  transform: none;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
.cursor-pointer {
  cursor: pointer;
}
.select-all {
  -webkit-user-select: all;
  -moz-user-select: all;
  user-select: all;
}
.resize {
  resize: both;
}
.\!flex-row {
  flex-direction: row !important;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}
.\!justify-end {
  justify-content: flex-end !important;
}
.\!justify-center {
  justify-content: center !important;
}
.justify-center {
  justify-content: center;
}
.\!justify-between {
  justify-content: space-between !important;
}
.justify-between {
  justify-content: space-between;
}
.gap-10 {
  gap: 2.5rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-8 {
  gap: 2rem;
}
.gap-\[1\.188rem\] {
  gap: 1.188rem;
}
.gap-\[1\.688rem\] {
  gap: 1.688rem;
}
.gap-\[30px\] {
  gap: 30px;
}
.gap-y-0 {
  row-gap: 0px;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-clip {
  overflow: clip;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.whitespace-pre-line {
  white-space: pre-line;
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}
.text-wrap {
  text-wrap: wrap;
}
.break-words {
  overflow-wrap: break-word;
}
.break-all {
  word-break: break-all;
}
.\!rounded-none {
  border-radius: 0 !important;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-none {
  border-radius: 0;
}
.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.border-s-0 {
  border-inline-start-width: 0px;
}
.\!bg-transparent {
  background-color: transparent !important;
}
.bg-\[\#30360d\] {
  --tw-bg-opacity: 1;
  background-color: rgb(48 54 13 / var(--tw-bg-opacity));
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-black\/50 {
  background-color: #00000080;
}
.bg-black\/60 {
  background-color: #0009;
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-clip-content {
  background-clip: content-box;
}
.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.\!p-0 {
  padding: 0 !important;
}
.p-0 {
  padding: 0;
}
.p-4 {
  padding: 1rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-8 {
  padding: 2rem;
}
.p-\[1px\] {
  padding: 1px;
}
.\!px-\[3\.75rem\] {
  padding-left: 3.75rem !important;
  padding-right: 3.75rem !important;
}
.\!py-\[1\.125rem\] {
  padding-top: 1.125rem !important;
  padding-bottom: 1.125rem !important;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.\!pb-\[2\.875rem\] {
  padding-bottom: 2.875rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pb-9 {
  padding-bottom: 2.25rem;
}
.pb-\[0\.375rem\] {
  padding-bottom: 0.375rem;
}
.pl-0 {
  padding-left: 0;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pl-\[1\.625rem\] {
  padding-left: 1.625rem;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pr-\[2\.063rem\] {
  padding-right: 2.063rem;
}
.pt-0 {
  padding-top: 0;
}
.pt-9 {
  padding-top: 2.25rem;
}
.pt-\[0\.5rem\] {
  padding-top: 0.5rem;
}
.pt-\[2\.25rem\] {
  padding-top: 2.25rem;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.align-middle {
  vertical-align: middle;
}
.\!text-\[0\.813rem\] {
  font-size: 0.813rem !important;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-\[0\.625rem\] {
  font-size: 0.625rem;
}
.text-\[0\.75rem\] {
  font-size: 0.75rem;
}
.text-\[0\.875rem\] {
  font-size: 0.875rem;
}
.text-\[1\.25rem\] {
  font-size: 1.25rem;
}
.text-\[1\.375rem\] {
  font-size: 1.375rem;
}
.text-\[1rem\] {
  font-size: 1rem;
}
.text-\[2\.375rem\] {
  font-size: 2.375rem;
}
.text-\[2\.625rem\] {
  font-size: 2.625rem;
}
.text-\[2rem\] {
  font-size: 2rem;
}
.text-\[42px\] {
  font-size: 42px;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.\!font-light {
  font-weight: 300 !important;
}
.font-bold {
  font-weight: 700;
}
.font-light {
  font-weight: 300;
}
.font-medium {
  font-weight: 500;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.\!uppercase {
  text-transform: uppercase !important;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.\!capitalize {
  text-transform: capitalize !important;
}
.capitalize {
  text-transform: capitalize;
}
.italic {
  font-style: italic;
}
.ordinal {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
    var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
}
.slashed-zero {
  --tw-slashed-zero: slashed-zero;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
    var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
}
.lining-nums {
  --tw-numeric-figure: lining-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
    var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
}
.oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
    var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
}
.proportional-nums {
  --tw-numeric-spacing: proportional-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
    var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
}
.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
    var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
}
.diagonal-fractions {
  --tw-numeric-fraction: diagonal-fractions;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
    var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
}
.stacked-fractions {
  --tw-numeric-fraction: stacked-fractions;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
    var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
}
.\!leading-tight {
  line-height: 1.25 !important;
}
.leading-4 {
  line-height: 1rem;
}
.leading-\[0\] {
  line-height: 0;
}
.leading-\[1\.55\] {
  line-height: 1.55;
}
.leading-\[2\.625rem\] {
  line-height: 2.625rem;
}
.leading-\[35\.2px\] {
  line-height: 35.2px;
}
.leading-\[42px\] {
  line-height: 42px;
}
.leading-none {
  line-height: 1;
}
.leading-tight {
  line-height: 1.25;
}
.\!-tracking-\[0\.020em\],
.\!-tracking-\[0\.02em\] {
  letter-spacing: -0.02em !important;
}
.\!tracking-\[-0\.84px\] {
  letter-spacing: -0.84px !important;
}
.tracking-\[0\.17em\] {
  letter-spacing: 0.17em;
}
.tracking-\[0\.32px\] {
  letter-spacing: 0.32px;
}
.tracking-\[0\.38px\] {
  letter-spacing: 0.38px;
}
.tracking-\[1\.44px\] {
  letter-spacing: 1.44px;
}
.tracking-normal {
  letter-spacing: 0em;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.text-\[\#000\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-\[\#f9f8f5\] {
  --tw-text-opacity: 1;
  color: rgb(249 248 245 / var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.underline {
  text-decoration-line: underline;
}
.overline {
  text-decoration-line: overline;
}
.line-through {
  text-decoration-line: line-through;
}
.no-underline {
  text-decoration-line: none;
}
.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-75 {
  opacity: 0.75;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
  outline-style: solid;
}
.outline-1 {
  outline-width: 1px;
}
.outline-2 {
  outline-width: 2px;
}
.-outline-offset-1 {
  outline-offset: -1px;
}
.-outline-offset-2 {
  outline-offset: -2px;
}
.outline-black {
  outline-color: #000;
}
.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
    drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.sepia {
  --tw-sepia: sepia(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}
.backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}
.transition {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.duration-100 {
  transition-duration: 0.1s;
}
.duration-200 {
  transition-duration: 0.2s;
}
.duration-300 {
  transition-duration: 0.3s;
}
.duration-\[var\(--duration\)\] {
  transition-duration: var(--duration);
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 0.3s;
}
cite,
address {
  font-style: normal !important;
}
h1,
h2,
h3,
h5,
h6 {
  text-transform: none !important;
}
.heading,
.heading-l,
.heading-xl {
  font-family: Tiempos Headline, Georgia, Times New Roman, Times, serif;
  line-height: 1 !important;
  letter-spacing: -0.01em !important;
  font-weight: lighter !important;
}
.heading b,
.heading-l b,
.heading-xl b,
.heading strong,
.heading-l strong,
.heading-xl strong {
  font-weight: 700;
}
.heading em,
.heading-l em,
.heading-xl em,
.heading i,
.heading-l i,
.heading-xl i {
  font-style: italic;
}
.heading-xl {
  font-size: 2rem;
}
@media (min-width: 640px) {
  .heading-xl {
    font-size: 4rem;
  }
}
.heading-l {
  font-size: 1.875rem;
}
@media (min-width: 640px) {
  .heading-l {
    font-size: 2.625rem;
  }
}
.button,
.card-wrapper .card-information__button .button,
.card-wrapper .card-information__button .button--small,
.card-wrapper .card-information__button .button--outline,
.button--outline,
.button:hover,
.button--small:hover,
.button--outline:hover,
.button--small {
  border-radius: 0.375rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3.125rem;
  min-height: 3.125rem;
  border: solid 0.0625rem;
  font-size: 14px !important;
  letter-spacing: normal;
  font-family: ES Rebond Grotesque TRIAL, Arial, Helvetica, sans-serif;
  font-weight: 700;
  background-color: #fff;
  min-width: unset;
  padding: 0 1rem;
}
.button--small {
  height: 1.625rem;
  min-height: 1.625rem;
  font-size: 0.75rem;
}
.image-with-blog .blog-section .hero__link {
  /* background: #685d51 !important; */
  font-size: 14px !important;
  color: #000;
}
.button--outline,
.button:hover,
.card-wrapper .card-information__button .button:hover,
.card-wrapper .card-information__button .button--small:hover,
.card-wrapper .card-information__button .button--outline:hover,
.button--small:hover,
.button--outline:hover {
  border: solid 1px currentColor;
}
.button_gray,
.button_gray:hover {
  background-color: rgb(var(--color-button-background));
  border-radius: 0;
  border: none;
  letter-spacing: 0.15rem;
  line-height: 0.9rem;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  padding: 0.75rem 1.875rem 0.625rem;
}
.header {
  width: 100%;
  background-color: #fff;
  z-index: 9999;
  padding: 0;
  display: flex;
  height: 4rem;
}
.header__right {
  margin: 0 1rem 0 0;
}
@media (min-width: 640px) {
  .header__right {
    margin: 0 2rem 0 0;
  }
}
.header__heading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  width: 13rem;
  transform: translate(-50%);
}
.header .header__icon {
  width: 2rem;
  height: 2rem;
}
.header .header__icon .icon {
  width: 1.25rem;
  height: 1.25rem;
}
.header .cart-count-bubble {
  font-size: 0.75rem;
  top: -0.75rem;
}
announcement-bar .announcement-slider {
  white-space: nowrap;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
announcement-bar .announcement-slider::-webkit-scrollbar {
  width: 0px;
}
.header__menu-item {
  font-family: ES Rebond Grotesque TRIAL, Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  display: inline-flex;
  letter-spacing: 0;
  padding: 0;
  color: #000;
}
.announcement-bar {
  font-family: ES Rebond Grotesque TRIAL, Arial, Helvetica, sans-serif !important;
  font-size: 0.6875rem !important;
  background-color: #685d51 !important;
  font-weight: 500;
  letter-spacing: normal !important;
  text-transform: lowercase;
}
.header--middle-left .header__inline-menu {
  min-width: unset;
  margin-inline-start: 2.188rem;
}
.header .header__right .header__icon {
  width: 100%;
}
.header__icon.header__icon--summary.header__icon--cart {
  width: unset;
}
@media screen and (max-width: 989px) {
  .header .header__left {
    margin-inline-start: 0.5rem;
    width: 100%;
  }
}
.header .localization-selector {
  height: 2.125rem;
}
.header .link-with-icon .icon {
  width: 1.125rem;
  height: 1.125rem;
}
.products-carousel__nav {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.products-carousel__nav::-webkit-scrollbar {
  width: 0px;
}
.products-carousel .button--small {
  opacity: 0.5;
  border-color: transparent;
}
.products-carousel .button--small:not(.js-switch-url) {
  border-radius: 0;
  padding: 0;
  margin: 0 1rem 0 0;
}
.products-carousel .button--small.active {
  opacity: 1;
  border-bottom: solid 1px;
}
.products-carousel .button--small.js-switch-url {
  border-color: currentColor;
  opacity: 1;
  min-width: 10rem;
}
.products-carousel .button--small:hover {
  height: 1.625rem;
  min-height: 1.625rem;
  font-size: 0.75rem;
}
.products-carousel .swiper-slide:first-child {
  margin-inline-start: 2rem;
}
.products-carousel .swiper-slide {
  height: auto;
}
.products-carousel .card-wrapper {
  height: 100%;
}
.products-carousel .card--product {
  background-color: unset;
}
.card-wrapper {
  display: flex !important;
  flex-direction: column;
  height: 100%;
}
.card-wrapper .card-information__wrapper .price {
  display: none;
}
.card-wrapper .card__badge {
  border-radius: 999px;
  font-family: ES Rebond Grotesque TRIAL, Arial, Helvetica, sans-serif;
  font-size: 0.625rem;
  max-width: 2.875rem;
  top: 0.625rem;
  right: 1.125rem;
}
.card-wrapper .card__badge .badge {
  width: 100%;
  color: #000;
  text-align: center;
  font-family: ES Rebond Grotesque TRIAL, Arial, Helvetica, sans-serif;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 90%;
  border: none;
  text-transform: none;
  padding: 0.875rem 0.5rem;
  border-radius: 50%;
}
.card-wrapper .card-information {
  justify-content: space-between !important;
  flex: 1 !important;
}
.card-wrapper .card-information__text,
.card-wrapper .card-information .h4,
.card-wrapper .card-information .price bdi {
  font-size: 0.8125rem;
  font-family: ES Rebond Grotesque TRIAL, Arial, Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 1;
  display: block;
}
.card-wrapper .card-information__text {
  margin-bottom: 0.75rem;
}
.card-wrapper .card-information__button {
  position: relative;
  top: auto;
  bottom: auto;
  transform: none;
  opacity: 1;
  height: auto;
  padding: 0;
}
.card-wrapper .card-information__button .button,
.card-wrapper .card-information__button .button--small,
.card-wrapper .card-information__button .button--outline {
  opacity: 1;
  position: relative;
  top: auto;
  left: auto;
  visibility: visible;
  transform: none;
  flex-direction: row-reverse;
  font-size: 0.8125rem;
  justify-content: space-between;
  margin: 0;
  padding-inline: 1rem;
  border-radius: 6px;
  background-color: #685d51 !important;
  color: #f2e9e0 !important;
  min-height: 40px !important;
}
.card-wrapper .card-information__button .button.custom__mega_cart-btn,
.card-wrapper .card-information__button .custom__mega_cart-btn.button--small,
.card-wrapper .card-information__button .custom__mega_cart-btn.button--outline {
  background-color: transparent !important;
  color: #000 !important;
  border: none !important;
  justify-content: left !important;
  padding-left: 0 !important;
}
.card-wrapper .card-information__button .button.custom__mega_cart-btn:after,
.card-wrapper
  .card-information__button
  .custom__mega_cart-btn.button--small:after,
.card-wrapper
  .card-information__button
  .custom__mega_cart-btn.button--outline:after {
  content: none !important;
}
.card-wrapper
  .card-information__button
  .button.custom__mega_cart-btn
  .custom__shop-button,
.card-wrapper
  .card-information__button
  .custom__mega_cart-btn.button--small
  .custom__shop-button,
.card-wrapper
  .card-information__button
  .custom__mega_cart-btn.button--outline
  .custom__shop-button {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.14;
  text-decoration: underline;
}
.card-wrapper .card-information__button .button--small {
  height: auto;
  font-size: 0.938rem;
}
.card-wrapper .card-information__button .price {
  background-color: #685d51 !important;
  color: #f2e9e0 !important;
}
.mega-menu-collection .card-wrapper .card__badge {
  display: none !important;
}
.hero {
  overflow: hidden;
  position: relative;
  color: #fff;
}
.hero__fig {
  height: 125vw;
}
@media (min-width: 768px) {
  .hero__fig {
    height: auto;
  }
}
.hero__media {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.hero__inner {
  max-width: 45.188rem;
  margin: 0 auto;
}
.hero__inner .hero__link {
  border: 0.0625rem solid white;
  color: #000;
}
.video-section .title-wrapper {
  max-width: 47.75rem;
  margin: 0 auto;
  width: 100%;
}
.video-section .title-wrapper .title {
  font-size: 1.5rem;
  text-transform: capitalize;
  font-weight: 300;
}
@media (min-width: 768px) {
  .video-section .title-wrapper .title {
    font-size: 2.625rem;
    letter-spacing: -0.02em !important;
  }
}
.video-section__media {
  padding-bottom: 54.85% !important;
  margin-block: 2rem;
}
.video-section__media .deferred-media__poster-button {
  border-radius: 999px;
  width: 4rem;
  height: 4rem;
}
@media (min-width: 640px) {
  .video-section__media .deferred-media__poster-button {
    width: 6rem;
    height: 6rem;
  }
}
.video-section .sub-heading {
  margin: 0 auto;
  font-family: Aileron;
}
.image-with-list .list p {
  font-family: ES Rebond Grotesque TRIAL, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 107%;
  letter-spacing: -0.02em;
}
.image-with-blog .blog {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
}
.image-with-blog .blog-content .blog-subtitle,
.image-with-results .results p {
  font-family: Aileron;
  line-height: 107%;
}
.image-with-results .results h2,
.image-with-results .results h3 {
  font-family: Tiempos Headline;
  line-height: 100%;
}
.image-with-results .results .hero__cta .hero__link {
  background-color: transparent !important;
}
.image-with-quotes .swiper-quotes-container .quote-title {
  font-family: Tiempos Headline;
  line-height: 108%;
  font-style: normal;
  color: #000;
}
.image-with-quotes .swiper-quotes-container h5 {
  font-family: Aileron;
}
.image-with-quotes .swiper-quotes-container .swiper-pagination-bullet {
  background: transparent;
  border: 1px solid #000000;
  height: auto;
  width: 0.75rem;
  margin: 0;
  opacity: 1;
}
.image-with-quotes .swiper-quotes-container .swiper-pagination-bullet-active {
  background: #000000;
}
.image-with-quotes .swiper-image-container .swiper-slide {
  opacity: 0 !important;
}
.image-with-quotes .swiper-image-container .swiper-slide-active {
  opacity: 1 !important;
}
.image-with-text-container {
  padding: 0 10.52%;
}
.image-with-text-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 8.35%;
}
.sticky-text_content {
  width: 59%;
}
.sticky-text_content > p {
  font-family: Aileron;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.38px;
}
.sticky_image {
  position: sticky;
  top: 70px;
  height: -moz-fit-content;
  height: fit-content;
}
@media (min-width: 768px) {
  .sticky_image {
    max-width: 40%;
  }
}
.sticky_image img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .image-with-text-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .sticky_image {
    position: static;
  }
}
@media screen and (max-width: 390px) {
  .sticky_image {
    width: 100%;
  }
}
.accordions .accordion-section_title {
  font-family: Tiempos Headline;
  line-height: 100%;
  letter-spacing: -0.02em;
}
.accordions .accordion-section_subtitle {
  font-family: Aileron;
  line-height: 131.25%;
}
.accordions .accordion-section_collapse .accordion:last-child {
  border-bottom: none;
}
.accordions .accordion-section_collapse .accordion__title {
  font-family: Aileron;
  line-height: 110%;
}
.accordions .accordion-section_collapse .accordion__content {
  font-family: Aileron;
  line-height: 150%;
}
.accordions .accordion-section_collapse .accordion .icon {
  transition: 0.2s ease-in;
}
.accordions .rotate {
  transform: rotate(45deg);
  transition: 0.2s ease-in;
}
.enable_effect details[open] p {
  color: #f19221;
}
.quote-swiper .swiper-pagination-bullet {
  background: transparent;
  border: 1px solid #f9f8f5;
  opacity: 1;
  margin-right: 10px !important;
}
.quote-swiper .swiper-pagination-bullet-active {
  background: #f9f8f5;
}
.quote-swiper .swiper {
  padding-bottom: 46px;
}
.quote_icon svg {
  width: 50px;
  height: 36.806px;
}
.quote_icon svg path {
  fill: #f9f8f5;
}
.quote_content {
  font-family: Aileron;
}
.left_blur,
.right_blur {
  display: block !important;
  width: 137px;
  height: 338px;
  z-index: 1;
  background: linear-gradient(
    91deg,
    #30360d -13.69%,
    rgba(48, 54, 13, 0.1) 134.19%
  );
}
@media screen and (max-width: 768px) {
  .quote_content {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 425px) {
  .quote_content {
    font-size: 1.25rem;
    line-height: 20px;
  }
  .left_blur,
  .right_blur {
    display: none !important;
  }
  .quote-swiper {
    padding-top: 2.25rem !important;
  }
}
.radio_container input {
  display: none;
}
.checkmark {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid #f9f8f5;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.radio_container input:checked ~ .checkmark:after {
  display: block;
}
.radio_container .checkmark:after {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #f9f8f5;
}
.subscribe_container {
  max-width: 613px;
}
.subscribe_heading .subscribe_main_heading {
  font-family: Tiempos Headline;
}
.agreement_text {
  font-family: ES Rebond Grotesque TRIAL, Arial, Helvetica, sans-serif;
  letter-spacing: normal;
  max-width: 367px;
}
.subscribe_btn {
  text-align: right;
  font-family: Aileron;
  line-height: normal;
}
.subscribe_btn,
.agreement_text {
  color: #f9f8f5;
}
.subscribe_email {
  max-width: 573px;
  padding: 19px 20px 19px 22px;
  width: 100%;
  border: 1px solid #f9f8f5;
  border-radius: 6px;
}
.subscribe_email form {
  display: flex;
  justify-content: space-between;
}
.subscribe_email form input {
  width: 100%;
  background: none;
  color: #fff;
}
.subscribe_email input[type="email"]:focus {
  outline: none;
  outline-offset: unset;
  box-shadow: none;
}
@media screen and (max-width: 425px) {
  .subscribe_heading .subscribe_main_heading {
    font-size: 2.25rem;
    line-height: 38px;
  }
  .subscribe_btn {
    font-size: 0.938rem;
  }
}
.footer {
  font-size: 0.875rem;
}
.footer .copyright__content {
  font-size: 0.6875rem;
}
.rc-subscription-details,
.rc-widget .px-3 {
  padding-left: 0 !important;
  letter-spacing: normal !important;
}
.rc-subscription-details {
  letter-spacing: normal !important;
  font-size: 12px !important;
}
.rc-selling-plans-dropdown__select {
  border: solid 1px;
  border-radius: 0.375rem;
}
.rc-radio.rc-radio--active ~ .rc-selling-plans {
  padding-left: 0 !important;
}
body,
html {
  font-size: unset;
  letter-spacing: normal;
}
.placeholder\:text-xs::-moz-placeholder {
  font-size: 0.75rem;
  line-height: 1rem;
}
.placeholder\:text-xs::placeholder {
  font-size: 0.75rem;
  line-height: 1rem;
}
.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}
.disabled\:cursor-default:disabled {
  cursor: default;
}
.disabled\:text-gray-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.disabled\:opacity-30:disabled {
  opacity: 0.3;
}
.group\/hero:hover .group-hover\/hero\:scale-\[var\(--scale\)\] {
  --tw-scale-x: var(--scale);
  --tw-scale-y: var(--scale);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group\/hero:hover
  .group-hover\/hero\:bg-\[color\:var\(--hvr-bgc\,_var\(--bgc\)\)\],
.group\/image:hover
  .group-hover\/image\:bg-\[color\:var\(--hvr-bgc\,_var\(--bgc\)\)\] {
  background-color: var(--hvr-bgc, var(--bgc));
}
.group\/hero:hover
  .group-hover\/hero\:opacity-\[var\(--hvr-op\,_var\(--op\)\)\],
.group\/image:hover
  .group-hover\/image\:opacity-\[var\(--hvr-op\,_var\(--op\)\)\] {
  opacity: var(--hvr-op, var(--op));
}
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}
.data-\[hidden\=true\]\:\!hidden[data-hidden="true"] {
  display: none !important;
}
.data-\[hidden\=true\]\:hidden[data-hidden="true"] {
  display: none;
}
.data-\[outline\=active\]\:p-\[1px\][data-outline="active"] {
  padding: 1px;
}
.data-\[outline\=active\]\:outline[data-outline="active"] {
  outline-style: solid;
}
.group[data-state="loading"] .group-data-\[state\=loading\]\:visible {
  visibility: visible;
}
.group[data-state="loading"] .group-data-\[state\=loading\]\:invisible {
  visibility: hidden;
}
@media (min-width: 768px) {
  .md\:absolute {
    position: absolute;
  }
  .md\:bottom-0 {
    bottom: 0;
  }
  .md\:left-0 {
    left: 0;
  }
  .md\:right-0 {
    right: 0;
  }
  .md\:top-0 {
    top: 0;
  }
  .md\:top-\[-1\.875rem\] {
    top: -1.875rem;
  }
  .md\:mb-\[2\.125rem\] {
    margin-bottom: 2.125rem;
  }
  .md\:mt-\[4\.25rem\] {
    margin-top: 4.25rem;
  }
  .md\:flex {
    display: flex;
  }
  .md\:w-2\/4 {
    width: 50%;
  }
  .md\:w-5\/6 {
    width: 83.333333%;
  }
  .md\:flex-row {
    flex-direction: row;
  }
  .md\:flex-col-reverse {
    flex-direction: column-reverse;
  }
  .md\:flex-wrap {
    flex-wrap: wrap;
  }
  .md\:justify-center {
    justify-content: center;
  }
  .md\:justify-between {
    justify-content: space-between;
  }
  .md\:gap-0 {
    gap: 0px;
  }
  .md\:gap-8 {
    gap: 2rem;
  }
  .md\:p-0 {
    padding: 0;
  }
  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .md\:px-\[8\.813rem\] {
    padding-left: 8.813rem;
    padding-right: 8.813rem;
  }
  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .md\:pb-0 {
    padding-bottom: 0;
  }
  .md\:pb-\[4\.125rem\] {
    padding-bottom: 4.125rem;
  }
  .md\:pb-\[6\.813rem\] {
    padding-bottom: 6.813rem;
  }
  .md\:pl-3 {
    padding-left: 0.75rem;
  }
  .md\:pl-\[8\%\] {
    padding-left: 8%;
  }
  .md\:pr-0 {
    padding-right: 0;
  }
  .md\:pr-\[3\%\] {
    padding-right: 3%;
  }
  .md\:pt-8 {
    padding-top: 2rem;
  }
  .md\:pt-\[5\.063rem\] {
    padding-top: 5.063rem;
  }
  .md\:pt-\[7\.3rem\] {
    padding-top: 7.3rem;
  }
  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .md\:text-\[2\.125rem\] {
    font-size: 2.125rem;
  }
  .md\:text-\[2\.625rem\] {
    font-size: 2.625rem;
  }
  .md\:text-\[4rem\] {
    font-size: 4rem;
  }
}
@media (min-width: 1024px) {
  .lg\:mt-0 {
    margin-top: 0;
  }
  .lg\:max-w-\[10\.688rem\] {
    max-width: 10.688rem;
  }
  .lg\:max-w-\[20\.5rem\] {
    max-width: 20.5rem;
  }
  .lg\:max-w-\[21\.563rem\] {
    max-width: 21.563rem;
  }
  .lg\:flex-1 {
    flex: 1 1 0%;
  }
  .lg\:p-10 {
    padding: 2.5rem;
  }
  .lg\:pl-\[13\.92\%\] {
    padding-left: 13.92%;
  }
  .lg\:pl-\[3\.625rem\] {
    padding-left: 3.625rem;
  }
  .lg\:pr-16 {
    padding-right: 4rem;
  }
  .lg\:pr-\[5\.063rem\] {
    padding-right: 5.063rem;
  }
  .lg\:pr-\[7\.47\%\] {
    padding-right: 7.47%;
  }
  .lg\:pt-\[5\.25rem\] {
    padding-top: 5.25rem;
  }
  .lg\:text-\[2\.125rem\] {
    font-size: 2.125rem;
  }
  .lg\:selection\:max-w-\[32\.875rem\] *::-moz-selection {
    max-width: 32.875rem;
  }
  .lg\:selection\:max-w-\[32\.875rem\] *::selection {
    max-width: 32.875rem;
  }
  .lg\:selection\:max-w-\[32\.875rem\]::-moz-selection {
    max-width: 32.875rem;
  }
  .lg\:selection\:max-w-\[32\.875rem\]::selection {
    max-width: 32.875rem;
  }
}
.\[\&\>svg\]\:\!h-6 > svg {
  height: 1.5rem !important;
}
.\[\&\>svg\]\:h-auto > svg {
  height: auto;
}
.\[\&\>svg\]\:\!w-6 > svg {
  width: 1.5rem !important;
}
.\[\&\>svg\]\:w-full > svg {
  width: 100%;
}
.\[\&_\*\]\:max-w-full * {
  max-width: 100%;
}
.\[\&_p\]\:inline p {
  display: inline;
}
.\[\&_p\]\:whitespace-pre-line p {
  white-space: pre-line;
}
.\[\&_p\]\:after\:whitespace-pre-wrap p:after {
  content: var(--tw-content);
  white-space: pre-wrap;
}
.\[\&_p\]\:after\:content-\[\'\\A\'\] p:after {
  --tw-content: "\a";
  content: var(--tw-content);
}

@media (max-width: 768px) {
  .header .header__heading svg {
    max-width: 155px;
  }
}
